html, body {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: "Oswald", "Open Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0, 0.2);
  border-radius: 3px;
  transition: all ease-in-out 2s;

  &:hover {
    transition: all ease-in-out 2s;
    background-color: rgba(0,0,0, 0.5);
  }
}

// Animations
@keyframes showUpLeft {
  from { opacity: 0; transform: translateX(-50vw) }
  to { opacity: 1; transform: translateX(0) }
}

@keyframes showUpRight {
  from { opacity: 0; transform: translateX(50vw) }
  to { opacity: 1; transform: translateX(0) }
}

@keyframes spinY {
  100% { transform: rotateY(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}
